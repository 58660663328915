import { Col, Row, Card } from 'react-bootstrap';
import { WrapperComponent } from '../components'
const NotFoundPage = () => {
    return(
    <WrapperComponent>
        <Card className='mt-5' >
            <Row className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <Col xs={12} md={5} xl={4} className="my-5">
                    <h6 className="text-uppercase text-muted mb-4">Errore 404</h6>
                    <h1 className="display-4 mb-3">Non c'è nessuna pagina qui 😭</h1>
                    <p className="text-muted mb-4">Sembra che tu sia finito qui per sbaglio! </p>

                </Col>
            </Row>
        </Card>
    </WrapperComponent>
    )
}

export default NotFoundPage


